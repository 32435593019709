export const createADatapoint = {
  order: 0,
  name: "Create a datapoint",
  keywords: "public safezone private visibility",
  subcategory: "Make database changes",
  markdown: `# Create a datapoint

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  {% inlineRouterLink articleId="what-is-a-datapoint?" %}Datapoints{% /inlineRouterLink %} are the basic building blocks of Tower Hunt. In nearly every place where you see real estate data, there are numerous opportunities to create datapoints. While each input provides its own field(s) and button(s) (and {% inlineRouterLink %}help documentation!{% /inlineRouterLink %}), the one constant element in datapoint creation is the clear privacy disclosure. Read on to make sure you understand which type of datapoint you are creating before you click "Save".

  ## Public

  In Tower Hunt, purple means public. When you are about to create a public datapoint, you'll see this prompt:

  ![Public visibility screenshot](https://assets.towerhunt.com/site/createPublicDatapoint0.png)

  {% callout type="caution" %}
  **Important:** Clicking "Save" on a public datapoint is actually just the first step toward making that information accessible to anyone on Tower Hunt. If you plan to contribute, it is important to {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}understand how datapoints become public{% /inlineRouterLink %}.
  {% /callout %}

  ## Safezone

  In Tower Hunt, yellow means {% inlineRouterLink articledId="what-is-safezone?" %}Safezone{% /inlineRouterLink %}, and Safezone means private. When you are about to create a Safezone datapoint, you'll see this prompt:

  ![Safezone visibility screenshot](https://assets.towerhunt.com/site/createSafezoneDatapoint0.png)
  `,
};
